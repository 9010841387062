<template>
  <v-text-field
    v-model="search"
    prepend-inner-icon="mdi-magnify"
    :label="$t('Search')"
    dense
    single-line
    clearable
    @click:clear="$emit('update:value', null)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        mdi-magnify
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
  export default {
    props: {
      value: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        search: this.value,
        awaitingSearch: false,
      }
    },
    watch: {
      search () {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.$emit('update:value', this.search)
            this.awaitingSearch = false
          }, 800)
        }
        this.awaitingSearch = true
      },
    },
  }
</script>
